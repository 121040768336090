import Axios from 'axios'
import messages from '@/messages'
import { DateTime } from 'luxon'
import SnakManager from '@/Lib/SnakManager'
import Store from '@/store/store'
import SyncDateTimesManager from '@/Lib/SyncDateTimesManager'

export default {
  async getDynamicFormDefinitions () {
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/DynamicFormDefinitions'
    try {
      const response = await Axios.get(
        URL,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )
      return JSON.parse(response.data)
    } catch (err) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'DynamicFormManager',
        FeatureName: 'getDynamicFormTabs()',
        ErrorCode: err.name,
        ErrorDescription: 'Failed to GET DynamicFormTabs.',
        AdditionalDetails: `Message: ${err.message}. Stack: ${err.stack}`,
      })
    }
  },
  async getDynamicFormConfig (id, lsConfigName) {
    
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/DynamicFormDef/' + id
    let config = ''

    // get an instance of the syncDateTimesManager
    const syncDateTimeManager = new SyncDateTimesManager(Store.$app.$storage)
    //  get the config lastSyncDateTime obj
    const lastSyncDateTime = syncDateTimeManager.getSyncDateTime(lsConfigName)
    // get the current dateTime
    const currentDateTime = DateTime.local()

    try {
      if (Store.getters.isOnline && (lastSyncDateTime === false || currentDateTime.diff(DateTime.fromISO(lastSyncDateTime.lastSync), 'minutes').toFormat('m') >= lastSyncDateTime.duration)) {
        const response = await Axios.get(
          URL,
          {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${Store.getters.authToken}`,
            },
          },
        )

        Store.$app.$storage.set(lsConfigName, JSON.parse(response.data))
      }

      config = Store.$app.$storage.get(lsConfigName)

      return config
    } catch (err) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'DynamicFormManager',
        FeatureName: 'getDynamicFormConfig()',
        ErrorCode: err.name,
        ErrorDescription: 'Failed to GET DynamicFormConfig. Id: ' + id,
        AdditionalDetails: `Error: ${err.message}. Stack: ${err.stack}`,
      })
    }
  },
  async putDynamicFormConfig (params) {
    const URL = Store.getters.azureProxyBaseURL + 'FieldVue/DynamicFormDef'
    try {
      const response = await Axios.put(
        URL,
        params,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${Store.getters.authToken}`,
          },
        },
      )
      const dynamicFormConfig = JSON.parse(response.data)

      return dynamicFormConfig
    } catch (err) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'DynamicFormManager',
        FeatureName: 'putDynamicFormConfig()',
        ErrorCode: err.name,
        ErrorDescription: 'Failed to PUT DynamicFormConfig.',
        AdditionalDetails: `Message: ${err.message} Stack: ${err.stack}`,
      })
    }
  },
  async getGroundWireDOPs () {
    const URL = `${Store.getters.serviceNowBaseURL}fmt/GetGroundWireDOPList`
    const snak = await SnakManager.getSNAK(Store.$app.$storage)
    try {
      const response = await Axios.get(URL,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${snak}`,
          },
        })
      return response.data.result
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'DynamicFormManager',
        FeatureName: 'getGroundWireDOPs',
        ErrorCode: 'Axios Error',
        ErrorDescription: 'Failed to retrieve Ground Wire DOPs.',
        AdditionalDetails: `Message: ${
          typeof error.message === 'object' && error.message !== null
            ? JSON.stringify(error.message)
            : error.message
        }. Stack: ${error.stack}`,
      })

      this.$notificationPlugin.add(
        DateTime.now().toISO(),
        DateTime.now().toISO(),
        '01905498-81eb-74e2-b174-affefae91c8a',
        messages.GENERAL_NETWORK_ERROR,
        'high',
        false
      )
    }
  },
  async getSafetyQualifications () {
    const URL = `${Store.getters.serviceNowBaseURL}fmt/GetSafetyQualificationsList`
    const snak = await SnakManager.getSNAK(Store.$app.$storage)
    try {
      const response = await Axios.get(URL,
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${snak}`,
          },
        })
      return response.data.result
    } catch (error) {
      Store.$app.$azureLogger.writeLogItem('error', {
        Application: 'DynamicFormManager',
        FeatureName: 'getSafetyQualifications',
        ErrorCode: 'Axios Error',
        ErrorDescription: 'Failed to retrieve New EE Checklists.',
        AdditionalDetails: `Message: ${
          typeof error.message === 'object' && error.message !== null
            ? JSON.stringify(error.message)
            : error.message
        }. Stack: ${error.stack}`,
      })

      this.$notificationPlugin.add(
        DateTime.now().toISO(),
        DateTime.now().toISO(),
        '01905498-81eb-74e2-b174-affefae91c8a',
        messages.GENERAL_NETWORK_ERROR,
        'high',
        false
      )
    }
  },
}
