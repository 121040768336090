const fvEnvVars = {
  all: {
    VUE_APP_I18N_LOCALE: 'en',
    VUE_APP_I18N_FALLBACK_LOCALE: 'en',
    VUE_APP_LOCAL_REST_SERVER_TLD_URI: 'http://localhost:3000',
    VUE_APP_ESRI_API_KEY: 'AAPTxy8BH1VEsoebNVZXo8HurIkedePx7G1kikfMno4CZY69_EIyROyGr4ZHejlB28H-1AknHZ1hQlCg6dQrMB298Dc9arNaXDzgAxgLgKeX8GgpqMBrmFJaLaz4xf6llNUF1uAM5R-aOayz-SEXWUzWRf27fm1rfnoWLI0oT61Jn2EuKwZC1XJy9z8ImXZipIVUdlfCJuj-cfA5d4WhsEJ63LLAGnQVnFjNi5HSGPjaRHE.AT1_8umoDtJ0',
    VUE_APP_ESRI_GEOCODE_URL: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/',
  },
  'localhost:8080': {
    // Base
    VUE_APP_Application: 'FieldVue',
    VUE_APP_ClientId: '1c133fb6-03c6-4d00-bf4a-cebf6cbad466',
    VUE_APP_KNOWN_AUTHORITIES: 'TSPrototype.b2clogin.com',
    VUE_APP_BASE_URL: 'http://localhost:8080/',
    // Project Dashboard
    //VUE_APP_CustomerProjectURL: 'https://webopstest.osmose.com/webopsrestservices_dev/api/osmose360API/GetO360ProjectDO/',
    // TSPrototype
    VUE_APP_Authority: 'https://TSPrototype.b2clogin.com/tfp/TSPrototype.onmicrosoft.com/B2C_1_FVSignUpSignIn',
    VUE_APP_Reset_Password_Policy_URL: 'https://TSPrototype.b2clogin.com/tfp/TSPrototype.onmicrosoft.com/B2C_1_FVPWDReset',
    VUE_APP_B2CScope: 'https://tsprototype.onmicrosoft.com/FV_B2C_DEV_m2/MSAL2.READ',
    // Webops rest services
    VUE_APP_AZURE_PROXY_BASE_URL: 'https://l24q4001.local.osmose.com/FieldVue.Api/api/',
    // VUE_APP_AZURE_PROXY_BASE_URL: 'https://fieldvuesvc-dev-osmose.azurewebsites.net/api/',
    // Service Now Api Base URL
    VUE_APP_SERVICE_NOW_BASE_URL: 'https://osmosedev.service-now.com/api/osmo2/',
    // Base url for Quality and Safety
    VUE_APP_WEBOPS_BASE_URL: 'https://l24q4001.local.osmose.com/',
    // VUE_APP_WEBOPS_BASE_URL: 'https://webopsdev.osmose.com/',
    // Azure PONI Query Service
    // VUE_APP_AZURE_PONI_QUERY_BASE_URL: 'https://webopsponiquery-appsvc-dev-osmose.azurewebsites.net/api/'
    VUE_APP_AZURE_PONI_QUERY_BASE_URL: 'https://l24q4001.local.osmose.com/PoniQueryRestService/api/'
  },
  'fieldvuedev.osmose.com': {
    // Base
    VUE_APP_Application: 'FieldVue',
    VUE_APP_ClientId: '1c133fb6-03c6-4d00-bf4a-cebf6cbad466',
    VUE_APP_KNOWN_AUTHORITIES: 'TSPrototype.b2clogin.com',
    VUE_APP_BASE_URL: 'https://fieldvuedev.osmose.com/',
    // Project Dashboard
    VUE_APP_CustomerProjectURL: 'https://webopstest.osmose.com/webopsrestservices_dev/api/osmose360API/GetO360ProjectDO/',
    // TSPrototype
    VUE_APP_Authority: 'https://TSPrototype.b2clogin.com/tfp/TSPrototype.onmicrosoft.com/B2C_1_FVSignUpSignIn',
    VUE_APP_Reset_Password_Policy_URL: 'https://TSPrototype.b2clogin.com/tfp/TSPrototype.onmicrosoft.com/B2C_1_FVPWDReset',
    VUE_APP_B2CScope: 'https://tsprototype.onmicrosoft.com/FV_B2C_DEV_m2/MSAL2.READ',
    // Webops rest services
    VUE_APP_AZURE_PROXY_BASE_URL: 'https://fieldvuesvc-dev-osmose.azurewebsites.net/api/',
    // Service Now Api Base URL
    VUE_APP_SERVICE_NOW_BASE_URL: 'https://osmosedev.service-now.com/api/osmo2/',
    // Base url for Quality and Safety
    VUE_APP_WEBOPS_BASE_URL: 'https://webopsdev.osmose.com/',
    // Azure PONI Query Service
    VUE_APP_AZURE_PONI_QUERY_BASE_URL: 'https://webopsponiquery-appsvc-dev-osmose.azurewebsites.net/api/',
  },
  'fieldvuetest.osmose.com': {
    // Base
    VUE_APP_Application: 'FieldVue',
    VUE_APP_ClientId: 'a2acc223-5158-4dab-9f0f-249f0626968a',
    VUE_APP_KNOWN_AUTHORITIES: 'OUSDashboardTest.b2clogin.com',
    VUE_APP_BASE_URL: 'https://fieldvuetest.osmose.com/',
    // Project Dashboard
    VUE_APP_CustomerProjectURL: 'https://webopstest.osmose.com/webopsrestservices_dev/api/osmose360API/GetO360ProjectDO/',
    // TSPrototype
    VUE_APP_Authority: 'https://OUSDashboardTest.b2clogin.com/tfp/OUSDashboardTest.onmicrosoft.com/B2C_1_FVSignUpSignIn',
    VUE_APP_Reset_Password_Policy_URL: 'https://OUSDashboardTest.b2clogin.com/tfp/OUSDashboardTest.onmicrosoft.com/B2C_1_FVPWDReset',
    VUE_APP_B2CScope: 'https://OUSDashboardTest.onmicrosoft.com/FV_B2C_TST_m2/MSAL2.READ',
    // Webops rest services
    VUE_APP_AZURE_PROXY_BASE_URL: 'https://fieldvuesvc-test-osmose.azurewebsites.net/api/',
    // Service Now Api Base URL
    VUE_APP_SERVICE_NOW_BASE_URL: 'https://osmosetest.service-now.com/api/osmo2/',
    // Base url for Quality and Safety
    VUE_APP_WEBOPS_BASE_URL: 'https://webopstest.osmose.com/',
    // Azure PONI Query Service
    VUE_APP_AZURE_PONI_QUERY_BASE_URL: 'https://webopsponiquery-appsvc-test-osmose.azurewebsites.net/api/',
  },
  'fieldvueuat.osmose.com': {
    // Base
    VUE_APP_Application: 'FieldVue',
    VUE_APP_ClientId: 'a2acc223-5158-4dab-9f0f-249f0626968a',
    VUE_APP_KNOWN_AUTHORITIES: 'OUSDashboardTest.b2clogin.com',
    VUE_APP_BASE_URL: 'https://fieldvueuat.osmose.com/',
    VUE_APP_POST_LOGOUT_REDIRECT_URI: 'https://fieldvueuat.osmose.com',
    // Project Dashboard
    VUE_APP_CustomerProjectURL: 'https://webopstest.osmose.com/webopsrestservices_dev/api/osmose360API/GetO360ProjectDO/',
    VUE_APP_PROJECT_DASHBOARD_URL: 'https://o360projectdataservice.azurewebsites.net/api/ProjectData/GetProjectData',
    // TSPrototype
    VUE_APP_Authority: 'https://OUSDashboardTest.b2clogin.com/tfp/OUSDashboardTest.onmicrosoft.com/B2C_1_FVSignUpSignIn',
    VUE_APP_Reset_Password_Policy_URL: 'https://OUSDashboardTest.b2clogin.com/tfp/OUSDashboardTest.onmicrosoft.com/B2C_1_FVPWDReset',
    VUE_APP_B2CScope: 'https://OUSDashboardTest.onmicrosoft.com/FV_B2C_TST_m2/MSAL2.READ',
    // Webops rest services
    VUE_APP_AZURE_PROXY_BASE_URL: 'https://fieldvuesvc-uat-osmose.azurewebsites.net/api/',
    // Service Now Api Base URL
    VUE_APP_SERVICE_NOW_BASE_URL: 'https://osmoseuat.service-now.com/api/osmo2/',
    // Base url for Quality and Safety
    VUE_APP_WEBOPS_BASE_URL: 'https://webopstest.osmose.com/',
    //  Media Service
    VUE_APP_GET_IMAGES_URL: 'https://mediasvc-dev-osmose.azurewebsites.net/api/mediadata/ListByCustomer',
    VUE_APP_MAKE_MEDIA_REQUEST_URL: 'https://mediasvc-dev-osmose.azurewebsites.net/api/mediadata/ProcessMediaDataRequest',
    VUE_APP_MEDIA_APP_KEY: '2d-hY[-abt6rcW3G:SMVxDGEaux7sU?6',
    VUE_APP_AZURE_PONI_QUERY_BASE_URL: 'https://webopsponiquery-appsvc-uat-osmose.azurewebsites.net/api/',
  },
  'fieldvue.osmose.com': {
    // Base
    VUE_APP_Application: 'FieldVue',
    VUE_APP_ClientId: '9fc03a3f-e56c-4e90-99c3-b6eb607ddead',
    VUE_APP_KNOWN_AUTHORITIES: 'OUSDashboardProd.b2clogin.com',
    VUE_APP_BASE_URL: 'https://fieldvue.osmose.com/',
    // Project Dashboard
    VUE_APP_CustomerProjectURL: 'https://webopsprod.osmose.com/webopsrestservices_dev/api/osmose360API/GetO360ProjectDO/',
    // TSPrototype
    VUE_APP_Authority: 'https://OUSDashboardProd.b2clogin.com/tfp/OUSDashboardProd.onmicrosoft.com/B2C_1_FVSignUpSignIn',
    VUE_APP_Reset_Password_Policy_URL: 'https://OUSDashboardProd.b2clogin.com/tfp/OUSDashboardProd.onmicrosoft.com/B2C_1_FVPWDReset',
    VUE_APP_B2CScope: 'https://OUSDashboardProd.onmicrosoft.com/FV_B2C_PROD_m2/MSAL2.READ/MSAL2.READ',
    // Webops rest services
    VUE_APP_AZURE_PROXY_BASE_URL: 'https://fieldvuesvc-prod-osmose.azurewebsites.net/api/',
    // Service Now Api Base URL
    VUE_APP_SERVICE_NOW_BASE_URL: 'https://osmose.service-now.com/api/osmo2/',
    // Base url for Quality and Safety
    VUE_APP_WEBOPS_BASE_URL: 'https://webops.osmose.com/',
  },
}

export default fvEnvVars
